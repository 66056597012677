@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

.footer-wrapper {
  background-color: @template-bg-color;
  min-height: 80px;
  overflow: hidden;
  
  .footer {
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: @template-text-color-light;
    position: relative;
    a {
      color: @template-text-color-light;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer-wrapper {
    .footer {
      font-size: 12px;
      &.home-page {
        padding: 0;
      }
      >div {
        width: 90%;
        margin: auto;
      }
    }
  }
}
