
// @import "~antd/lib/style/v2-compatible-reset.less";

body {
  word-wrap: break-word;
  font-size: 14px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6
{
  color: rgba(0,0,0,.85);
    font-weight: 500
}

/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */

.video {
  max-width: 800px;
}

#root {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}



@line-color: #e9e9e9;

@shadow-color: rgba(0, 0, 0, 0.15);

@bottom-bar-bg-color: #262626;
@bottom-bar-line-color: #000;

@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-nav-bg-color: #001529;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;

@animate-duration: .45s;

@text-color: #314659;

// /* 详细页图片或框框的样式;
// */
// .page-shadow() {
//   box-shadow: 0 5px 8px @shadow-color;
// }

// .page-pro() {
//   border-radius: 6px;
//   border: 1px solid @line-color;
//   transform: translateY(0);
//   transition: transform .3s @ease-out, box-shadow .3s @ease-out;
//   &:hover {
//     .page-shadow();
//     transform: translateY(-5px);
//   }
// }
